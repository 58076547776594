<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-receipt"
          :title="this.$t('dashboard.count_orders')"
          :value="this.$store.getters['order/totalOrders']"
          sub-icon="mdi-calendar"
          :sub-text="this.$t('date.today')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-new-box"
          :title="this.$t('dashboard.new_orders')"
          :value="this.$store.getters['order/totalNewOrders']"
          sub-icon="mdi-calendar"
          :sub-text="this.$t('date.today')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          :title="this.$t('dashboard.sales')"
          :value="this.$store.getters['order/totalRevenue'] | currency"
          sub-icon="mdi-calendar"
          :sub-text="this.$t('date.today')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-stove"
          :title="this.$t('dashboard.orders_in_production')"
          :value="this.$store.getters['order/ordersInProduction']"
          sub-icon="mdi-calendar"
          :sub-text="this.$t('date.today')"
        />
      </v-col>
      <v-col cols="12">
        <base-material-card
          color="indigo"
          icon="mdi-receipt"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              {{ $t('dashboard.orders_today') }}
            </div>
          </template>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Search"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-3" />

          <v-data-table
            :headers="headers"
            :items="this.$store.getters['order/ordersToday']"
            :search.sync="search"
            :items-per-page="5"
            :sort-desc="[false, true]"
            multi-sort
          >
            <template
              v-slot:item.delivery_date="{ item }"
            >
              <v-chip
                :color="getDeliveryColor(item)"
              >
                {{ item.delivery_date | datetime }}
              </v-chip>
            </template>
            <template
              v-slot:item.total="{ item }"
            >
              {{ item.total | currency }}
            </template>
            <template
              v-slot:item.order_items="{ item }"
            >
              <tr
                v-for="order_item in item.order_items"
                :key="order_item.id"
              >
                {{ order_item.product_name }} ({{ order_item.quantity }}x)
              </tr>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                medium
                class="mr-2"
                @click="viewOrder(item)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',
    data () {
      return {
        viewedItem: null,
        viewedIndex: null,
        dialog: false,
        headers: [
          {
            text: this.$t('orders.headers.order_number'),
            value: 'order_number',
          },
          {
            text: this.$t('orders.headers.status'),
            value: 'application_status',
          },
          {
            text: this.$t('orders.headers.delivery_date'),
            value: 'delivery_date',
          },
          {
            text: this.$t('orders.headers.customer_name'),
            value: 'customer_name',
          },
          {
            text: this.$t('orders.headers.customer_note'),
            value: 'customer_note',
          },
          {
            text: this.$t('orders.headers.order_items'),
            value: 'order_items',
          },
          {
            text: this.$t('orders.headers.total'),
            value: 'total',
          },
          {
            sortable: false,
            text: this.$t('orders.headers.actions'),
            value: 'actions',
          },
        ],
        items: [],
        search: undefined,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    beforeCreate () {
      this.$store
        .dispatch('application/getConfiguration')
        .then(response => {
          this.$store.commit('application/configuration', response.data)
        })
        .catch(response => {
          this.$store.commit('viewstate/notify', {
            color: 'error',
            text:
              this.$t('error.configuration'),
          })
        })
        .finally(() => {
          this.loading = false
        })
      this.$store
        .dispatch('order/getOrdersTodayV1')
        .then(response => {
          this.$store.commit('order/ordersToday', response.data)
        })
        .catch(response => {
          this.$store.commit('viewstate/notify', {
            color: 'error',
            text:
              this.$t('error.orders_retrieve'),
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      getDeliveryColor (order) {
        const productionLeadtimeMilliSeconds = this.$store.getters['application/configuration'].production_leadtime * 60000
        if (order.application_status === 'in_production') {
          return this.$store.getters['application/configuration'].in_production_label_color
        } else if (order.application_status === 'new') {
          const timezoneOffset = new Date(order.delivery_date).getTimezoneOffset()
          const time = new Date(order.delivery_date).getTime() + ((new Date().getTimezoneOffset() - timezoneOffset) * 60000)
          if ((new Date().getTime() + productionLeadtimeMilliSeconds) < time) {
            return this.$store.getters['application/configuration'].new_order_label_color
          } else {
            return this.$store.getters['application/configuration'].should_be_in_production_label_color
          }
        } else {
          return '#FFF'
        }
      },
      viewOrder (item) {
        this.$router.push({
          name: 'OrderOverview',
          params: {
            id: item.id,
          },
        })
      },
      close () {
        this.viewedIndex = null
        this.viewedItem = null
        this.dialog = false
      },
      finish (item) {
        // Finish
        this.dialog = false
        this.viewedIndex = null
        this.viewedItem = null
      },
    },
  }
</script>
